<template>
  <sign-page
    title-text="请假条历史记录"
    :request="request"
    :column-list="columnList"
    table-size="small"
    :table-actions="tableActions"
    @tableAction="tableAction"
    :title-menus="[]"
    :table-actions-fixed="true"
    :need-data-file="true">
    <fm-modal title="请假条详细" v-model="modal" width="1000px" v-if="modal">
      <detail :statusMap="statusMap" :status-config="statusConfig" :id="chooseData.id"></detail>
    </fm-modal>
  </sign-page>
</template>

<script>
import {
  askLeaveApplyRequest as request,
  statusManageRequest
} from '@/api'

import TextCell from '@/components/base/TextCell'

import detail from './apply/detail'

export default {
  components: {
    detail
  },
  computed: {
    tableActions () {
      return [{
        key: 'detail',
        label: '明细'
      },
      {
        key: 'back',
        label: '撤销',
        show (data) {
          return data.actions.find(v => v.key === 'back_status_switch') && true
        }
      }]
    },
    columnList: {
      get () {
        let data = [{
          title: '编码',
          field: 'id',
          sort: true
        },
        {
          title: '请假人',
          field: 'workerName',
          sort: true
        },
        {
          title: '请假类型',
          field: 'type',
          sort: true
        },
        {
          title: '申请时间',
          field: 'createTime',
          dataType: Date,
          sort: true,
          render: (h, rowData) => {
            return h('div', rowData && rowData.createTime ? rowData.createTime.slice(0, 10) : '-')
          }
        },
        {
          title: '开始时间',
          field: 'startTime',
          dataType: Date,
          sort: true,
          render: (h, rowData) => {
            return h('div', rowData && rowData.startTime ? rowData.startTime.slice(0, 10) : '-')
          }
        },
        {
          title: '结束时间',
          field: 'endTime',
          dataType: Date,
          sort: true,
          render: (h, rowData) => {
            return h('div', rowData && rowData.endTime ? rowData.endTime.slice(0, 10) : '-')
          }
        },
        {
          title: '请假时长',
          field: 'timeLong',
          sort: true
        },
        {
          title: '请假事由',
          field: 'reson',
          sort: true,
          render: (h, row) => {
            return h(TextCell, {
              props: {
                value: row.reson
              }
            })
          }
        },
        {
          title: '状态',
          field: 'statusText',
          sort: true
        }]
        return data
      }
    }
  },
  methods: {
    async tableAction (parm, vm) {
      this.chooseData = parm.data
      if (parm.action === 'detail') {
        this.modal = true
      } else {
        let action = this.chooseData.actions.find(v => v.key === 'back_status_switch')
        if (action) {
          const result = await this.$dialog.confirm({title: '系统提示', content: '确定' + action.label + '该请假条吗?'})
          if (result) {
            await request.switchStatus(this.chooseData.id, {
              statusActionKey: action.key,
              statusActionRecordId: action.statusActionRecordId,
            })
            vm.loadData()
          }
        }
      }
    },
    async loadConfig () {
      let c = await statusManageRequest.config({
        statusGroupKey: 'askLeaveApply'
      })
      c.statusList.forEach(v => {
        this.statusMap[v.statusKey] = v.label
      })
      let d = await request.getType()
      this.applyTypeList = d.map(v => {
        return {
          key: String(v.id),
          label: v.label
        }
      })
      this.config = {}
      this.applyTypeList.forEach(a => {
        this.config[a.key] = c.statusSwitchList.filter(v => v.isHost && (!v.tag || v.tag === a.key))
      })
      this.config['null'] = c.statusSwitchList.filter(v => v.isHost && !v.tag)
    },
    async loadData (parm) {
      return await request.get(parm)
    }
  },
  created () {
    this.loadConfig()
  },
  data () {
    return {
      request: {
        get: this.loadData
      },
      applyTypeList: [],
      statusMap: {},
      statusConfig: {},
      modal: false,
      chooseData: null
    }
  }
}
</script>
